<template>
  <section>
    <div>
      <div class="title">
        <span>
          {{ $t('select_product') }}:
        </span>
      </div>
      <div class="products">
        <product @select="selectProduct" v-for="product in products"
                 :is-selected="user.product_id === product.product_id"
                 :product="product"
                 :key="product.product_id"
        />
      </div>
      <generic-input :disabled="!selectedProduct"
                     v-model="user.qty"
                     @blur="$v.user.qty.$touch()"
                     @input="$emit('validation-changed', !$v.$invalid)"
                     type="number"
                     name="qty"
                     :min="min"
                     :max="max"
                     :placeholder="$t('qty_to_buy_field')"
                     :validations="[
                       {
                         condition: $v.user.qty.$error && !$v.user.qty.required,
                         text: $t('required')
                       },
                       {
                         condition: $v.user.qty.$error && !$v.user.qty.between,
                         text: $t('qty_must_be_between', { min, max })
                       }
                     ]"
      />
      <div class="total-price" :class="{ active: selectedProduct }">
        {{ $t('total_price') }}: ${{ total }}
      </div>
    </div>
  </section>
</template>
<script>
import request from '@/server/request'
import GenericInput from '@/components/Core/Form/GenericInput'
import Product from '@/components/JoinUsModal/BuyingRequestCreating/Product'
import { required, between } from 'vuelidate/lib/validators'

export default {
  name: 'BuyingRequestCreating',
  components: {
    GenericInput,
    Product
  },
  props: {
    payload: {
      type: Object,
      required: false
    }
  },
  computed: {
    selectedProduct () {
      return this.products.find(p => p.product_id === this.user.product_id)
    },
    max () {
      return this.selectedProduct ? this.selectedProduct.maximum_saleable : 0
    },
    min () {
      return this.selectedProduct ? this.selectedProduct.minimum_saleable : 0
    },
    total () {
      return this.selectedProduct ? this.user.qty * this.selectedProduct.base_price_per_unit : 0
    }
  },
  data: () => ({
    products: [],
    user: {
      qty: 0,
      product_id: null
    }
  }),
  validations: {
    user: {
      qty: {
        required,
        between (value) {
          return between(this.min, this.max)(value)
        }
      }
    }
  },
  mounted () {
    this.loadProduct()
  },
  methods: {
    async loadProduct () {
      if (!this.products.length) {
        await request({ entity: 'auction.buy_request', action: 'load_product' })
          .then(result => {
            this.products = result.data
            this.user.product_id = this.products[0].product_id
            this.user.qty = this.min
            this.$v.user.qty.$touch()
            this.$emit('validation-changed', !this.$v.$invalid)
          })
      }
    },
    selectProduct (productId) {
      this.user.product_id = productId
      this.$v.user.qty.$touch()
    }
  }
}
</script>
<style scoped>
.title {
  text-align: left;
}

.title span, .total-price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #649187;
}

.products {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 25px;
}

.total-price {
  margin-bottom: 25px;
  text-align: end;
  font-size: 16px;
}
</style>
