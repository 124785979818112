<template>
  <div class="product" :class="{ selected: isSelected }">
    <div class="product-info">
      <div class="product-image">
        <img :src="product.image_url" />
      </div>
      <div class="product-specs">
        <ul>
          <li>
            {{ $t('parameters') }}
          </li>
          <li>
            {{ $t('growing_percentage') }}: {{ product.growing_percentage }}
          </li>
          <li>
            Етап росту: 1
          </li>
          <li v-if="maxGrowingAge">
            {{ $t('maximal_growing_age') }}: {{ maxGrowingAge }}
          </li>
          <li>
            {{ $t('price') }}: ${{ product.base_price_per_unit }}
          </li>
        </ul>
      </div>
    </div>
    <div class="action">
      <div @click="$emit('select', product.product_id)" class="select">{{ product.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Product',
  props: {
    product: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    maxGrowingAge () {
      if (!this.product) {
        return null
      }
      return this.product.final_growing_date
    }
  }
}
</script>
<style scoped>
.product {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 180px;
  border: 1px solid #BDE5DD;
  border-radius: 5px;
  margin-right: 5px;
}

.product.selected {
  border: 1px solid #0BCA7C;
}

.product-info {
  display: flex;
  flex: 1 1;
}

.product-specs {
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 100%;
  color: #649187;
}

.product-specs li {
  list-style-image: unset;
}

.action .select {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #649187;
  background: #BDE5DD;
  border: 1px solid #BDE5DD;
  border-radius: 5px;
  padding: 12px 44px;
  cursor: pointer;
}

.selected .action .select {
  color: #FFFFFF;
  background: #0BCA7C;
  border: 1px solid #0BCA7C;
}
</style>
