var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[_c('div',{staticClass:"title"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('select_product'))+": ")])]),_c('div',{staticClass:"products"},_vm._l((_vm.products),function(product){return _c('product',{key:product.product_id,attrs:{"is-selected":_vm.user.product_id === product.product_id,"product":product},on:{"select":_vm.selectProduct}})}),1),_c('generic-input',{attrs:{"disabled":!_vm.selectedProduct,"type":"number","name":"qty","min":_vm.min,"max":_vm.max,"placeholder":_vm.$t('qty_to_buy_field'),"validations":[
                     {
                       condition: _vm.$v.user.qty.$error && !_vm.$v.user.qty.required,
                       text: _vm.$t('required')
                     },
                     {
                       condition: _vm.$v.user.qty.$error && !_vm.$v.user.qty.between,
                       text: _vm.$t('qty_must_be_between', { min: _vm.min, max: _vm.max })
                     }
                   ]},on:{"blur":function($event){return _vm.$v.user.qty.$touch()},"input":function($event){return _vm.$emit('validation-changed', !_vm.$v.$invalid)}},model:{value:(_vm.user.qty),callback:function ($$v) {_vm.$set(_vm.user, "qty", $$v)},expression:"user.qty"}}),_c('div',{staticClass:"total-price",class:{ active: _vm.selectedProduct }},[_vm._v(" "+_vm._s(_vm.$t('total_price'))+": $"+_vm._s(_vm.total)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }